import {faFacebook, faInstagram} from "@fortawesome/free-brands-svg-icons";
import {faPlus, faBars} from "@fortawesome/free-solid-svg-icons";
import DOMPurify from "dompurify";
import React, {useState} from "react";

export const JSON_HEADERS = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};

export const UI_ACTIONS = {
  TOGGLE_SHOW_ADD_NEW_PAGE_MODAL_VISIBILITY: "TOGGLE_SHOW_ADD_NEW_PAGE_MODAL_VISIBILITY",
  TOGGLE_SHOW_FEEDBACK_MODAL_VISIBILITY: "TOGGLE_SHOW_FEEDBACK_MODAL_VISIBILITY",
  SHOW_MODERATION: "SHOW_MODERATION",
  SHOW_PREVIEW_PANEL: "SHOW_PREVIEW_PANEL",
  SHOW_EMOJI_MODAL: "SHOW_EMOJI_MODAL",
  SHOW_SAVED_REPLIES: "SHOW_SAVED_REPLIES",
  REPLY_SENT_ADD: "REPLY_SENT_ADD",
  REPLY_SENT_DELETE: "REPLY_SENT_DELETE",
  SHOW_SEARCH: "SHOW_SEARCH",
  SET_PREVIEW_DATA: "SET_PREVIEW_DATA",
  SHOW_BRANDS_MODAL: "SHOW_BRANDS_MODAL",
  SET_SELECT_TYPE: "SET_SELECT_TYPE",
  SELECTED_COMMENTS_COUNT: "SELECTED_COMMENTS_COUNT"
}

export const PAGES_ACTIONS = {
  LOAD_FACEBOOK_PAGES_STARTED: "LOAD_FACEBOOK_PAGES_STARTED",
  LOAD_FACEBOOK_PAGES_COMPLETED: "LOAD_FACEBOOK_PAGES_COMPLETED",
  LOAD_FACEBOOK_PAGES_FAILED: "LOAD_FACEBOOK_PAGES_FAILED",
  REQUEST_PAGE_DETAILS_SUCCESS: "REQUEST_PAGE_DETAILS_SUCCESS",
  RESET_PAGE_DETAILS: "RESET_PAGE_DETAILS",
  ADD_PAGE_DETAILS: "ADD_PAGE_DETAILS",
  UPDATE_PAGE_DETAILS: "UPDATE_PAGE_DETAILS",
  REMOVE_FACEBOOK_PAGES_FROM_ACTIVE: "REMOVE_FACEBOOK_PAGES_FROM_ACTIVE",
  ADD_COMMENT_WEBHOOK: "ADD_COMMENT_WEBHOOK",
  CHANGE_PAGE: "CHANGE_PAGE",
  DELETE_COMMENT_WEBHOOK: "DELETE_COMMENT_WEBHOOK",
  INC_UNREVIEWED_COMMENTS_COUNTER: "INC_UNREVIEWED_COMMENTS_COUNTER",
  DEC_UNREVIEWED_COMMENTS_COUNTER: "DEC_UNREVIEWED_COMMENTS_COUNTER",
  SET_UNREVIEWED_COMMENTS_COUNTER: "SET_UNREVIEWED_COMMENTS_COUNTER",

  REQUEST_RULES: "REQUEST_RULES",
  ADD_RULES: "ADD_RULES",
  UPDATE_RULES: "UPDATE_RULES",
  REMOVE_RULES: "REMOVE_RULES"
}

export const COMMENTS_ACTIONS = {
  REQUEST_PAGE_COMMENTS_STARTED: "REQUEST_PAGE_COMMENTS_STARTED",
  REQUEST_PAGE_COMMENTS_FAILED: "REQUEST_PAGE_COMMENTS_FAILED",
  REQUEST_PAGE_COMMENTS_SUCCESS: "REQUEST_PAGE_COMMENTS_SUCCESS",
  ADD_NEW_COMMENT: "ADD_NEW_COMMENT",
  ADD_NEW_TMP_COMMENT: "ADD_NEW_TMP_COMMENT",
  RESET_TMP_COMMENTS: "RESET_TMP_COMMENTS",
  ADD_NEW_COMMENTS: "ADD_NEW_COMMENTS",
  DELETE_COMMENT: "DELETE_COMMENT",
  DELETE_COMMENTS: "DELETE_COMMENTS",
  UPDATE_COMMENT: "UPDATE_COMMENT",
  CHANGE_FILTER: "CHANGE_FILTER",
  LOAD_LINKS: "LOAD_LINKS",
  CHANGE_LINKS: "CHANGE_LINKS",
  BAN_USER: "BAN_USER",
  FLAG_USER: "FLAG_USER",
  HIDE_ALL_REPLY_FORMS: "HIDE_ALL_REPLY_FORMS",
  SHOW_REPLY_FORM: "SHOW_REPLY_FORM",
  REQUEST_USER_NOTIFICATIONS: "REQUEST_USER_NOTIFICATIONS",
  ADD_USER_NOTIFICATIONS: "ADD_USER_NOTIFICATIONS",
  UPDATE_USER_NOTIFICATIONS: "UPDATE_USER_NOTIFICATIONS",
  REMOVE_USER_NOTIFICATIONS: "REMOVE_USER_NOTIFICATIONS"
}

export const USER_ACTIONS = {
  REQUEST_USER_DETAILS_STARTED: "REQUEST_USER_DETAILS_STARTED",
  REQUEST_USER_DETAILS_SUCCESS: "REQUEST_USER_DETAILS_SUCCESS",
  REQUEST_USER_DETAILS_FAILED: "REQUEST_USER_DETAILS_FAILED",
  ADD_FACEBOOK_PAGES_TO_USER: "ADD_FACEBOOK_PAGES_TO_USER",
  REMOVE_FACEBOOK_PAGES_FROM_USER: "REMOVE_FACEBOOK_PAGES_FROM_USER",
  INC_PAGES_COUNT: "INC_PAGES_COUNT",
  DEC_PAGES_COUNT: "DEC_PAGES_COUNT",
  UPDATE_USER_SUB_USERS: "UPDATE_USER_SUB_USERS",
  REQUEST_SAVED_REPLIES: "REQUEST_SAVED_REPLIES",
  ADD_SAVED_REPLIES: "ADD_SAVED_REPLIES",
  UPDATE_SAVED_REPLIES: "UPDATE_SAVED_REPLIES",
  REMOVE_SAVED_REPLIES: "REMOVE_SAVED_REPLIES",
  REQUEST_USER_NOTIFICATIONS: "REQUEST_USER_NOTIFICATIONS",
  ADD_USER_NOTIFICATIONS: "ADD_USER_NOTIFICATIONS",
  UPDATE_USER_NOTIFICATIONS: "UPDATE_USER_NOTIFICATIONS",
  REMOVE_USER_NOTIFICATIONS: "REMOVE_USER_NOTIFICATIONS",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  API_ERROR: "API_ERROR",
  CHANGE_PAGE: "CHANGE_PAGE"
}

export const BRANDS_ACTIONS = {
  REQUEST_BRANDS: "REQUEST_BRANDS",
  ADD_BRANDS: "ADD_BRANDS",
  UPDATE_BRANDS: "UPDATE_BRANDS",
  REMOVE_BRANDS: "REMOVE_BRANDS",
  INIT_PAGES: "INIT_PAGES",
  ADD_PAGES: "ADD_PAGES",
  REMOVE_PAGES: "REMOVE_PAGES",
  INIT_CURRENT_PAGES: "INIT_CURRENT_PAGES",
  ADD_CURRENT_PAGES: "ADD_CURRENT_PAGES",
  REMOVE_CURRENT_PAGES: "REMOVE_CURRENT_PAGES",
  SET_SELECTED_BRAND: "SET_SELECTED_BRAND",
  UPDATE_STATUS: "UPDATE_STATUS"
}

export const DIRECT_MESSAGES_ACTIONS = {
  REQUEST_MESSAGES: "REQUEST_MESSAGES",
  REQUEST_CONVOS: "REQUEST_CONVOS",
  ADD_DIRECT_MESSAGES: "ADD_DIRECT_MESSAGES",
  DELETE_DIRECT_MESSAGE: "DELETE_DIRECT_MESSAGE",
  UPDATE_DIRECT_MESSAGE: "UPDATE_DIRECT_MESSAGE",
  ADD_CONVO: "ADD_CONVO",
  UPDATE_CONVO: "UPDATE_CONVO",
  DELETE_CONVO: "DELETE_CONVO",
  ADD_MESSAGE_WEBHOOK: "ADD_MESSAGE_WEBHOOK",
  DELETE_MESSAGE_WEBHOOK: "DELETE_MESSAGE_WEBHOOK",
  ADD_CONVO_WEBHOOK: "ADD_CONVO_WEBHOOK",
  ADD_LAST_ACTIVE: "ADD_LAST_ACTIVE",
  CHANGE_DM_FILTER: "CHANGE_DM_FILTER",
  SET_UNARCHIVED_CONVOS_COUNT: "SET_UNARCHIVED_CONVOS_COUNT",
  UPDATE_MESSAGE_WEBHOOK: "UPDATE_MESSAGE_WEBHOOK"
}

export const CSRF = document.querySelector("meta[name='csrf-token']").getAttribute("content");

export const webPurifyLangs = { en: 'English', ar: 'Arabic', fr: 'French', de: 'German', hi: 'Hindi', ja: 'Japanese',
  it: 'Italian', pt: 'Portuguese', ru: 'Russian', es: 'Spanish', th: 'Thai', tr: 'Turkish',
  zh: 'Chinese (beta)',
  ko: 'Korean (beta)',
  pa: 'Punjabi (beta)' }

export const getIcon = (type) => {
  switch (type) {
    case "facebook_page":
      return faFacebook
      break;
    case "instagram_page":
      return faInstagram
      break;
    case "dashboard":
      return faBars
      break;
    default:
      return faPlus
  }
}

const defaultOptions = {
  ALLOWED_TAGS: [ 'strong', 'a', 'br' ],
  ALLOWED_ATTR: ['target', 'href']
};

const sanitize = (dirty, options) => ({
  __html: DOMPurify.sanitize(
      dirty,
      { ...defaultOptions, ...options }
  )
});

export const SanitizeHTML = ({ html, options, className}) => (
    <div className={className} dangerouslySetInnerHTML={sanitize(html, options)} />
);

export const rolesInfo = {
  moderator: "<strong>Moderators</strong> have full access to comments, but they do not have access to moderation settings or account wide settings (like adding or removing pages).",
  manager: "<strong>Managers</strong> have full access to comments and moderation settings, but they do not have access to account wide settings (like adding or removing pages).",
  administrator: "<strong>Administrators</strong> have full access to comments, moderation settings and account wide settings (like adding or removing pages)."
}

export const filterList = {
  status: ["Hidden", "Visible"],
  comments_type: ["Ad comments", "Organic comments"],
  label: ["Profanity","Sentiment","URL","Email & Phone","Mentions & Hashtags","Image","Emoji","All"],
  replied: ["Yes", "No"],
  intent: ["Needs reply"],
  time: ["Last 24 hours","Last 7 days","Last 14 days","Last 30 days"]
}

export const isIE = () => {
  const userAgent = navigator.userAgent;
  return (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident") > -1);
}

export const numberWithCommas = (x) => {
  return x.toLocaleString();
}

export const defaultSystemInstruction = 'You are a customer support agent that will answer to comments and support questions based on your information about the company, brand, products, services, etc.\n\n' +
    'Always answer from the second-person point of view. You are an employee the company so always say "we" and "our" and never "they" or "their".\n\n' +
    'Don’t give a factual answer about something that is not explicitly listed in the information that is available to you.\n\n' +
    'Just give the answer and not add sentences to describe what you\'re going to do like: "Sure, I can help you with that. Let me search through our files to find the information, just a moment."'